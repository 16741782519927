<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-base">
      <vx-card>
        <div class="vx-row">
          <div class="vx-col sm:w-1/3 w-full">
            <vs-radio v-model="radioBtn" vs-value="01">
              <span>เลือกพื้นที่/กลุ่ม</span>
            </vs-radio>
            <v-select :options="areas" :value="areas" :clearable="false" @input="setArea" v-model="selectedArea"  style="display: inline-block; width: 170px; margin-left: 10px" :disabled="disable01"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-radio v-model="radioBtn" vs-value="02">
              <span>เลขทะเบียนผู้ใช้น้ำ</span>
            </vs-radio>
            <vs-input v-model="inputNumber" @keydown="onKeydown($event)" style="width: 170px; display: inline-block; margin-left: 10px" :disabled="disable02"/>
          </div>
          <div class="vx-col sm:w-1/3 w-full">
            <vs-radio v-model="radioBtn" vs-value="03">
              <span>ชื่อผู้ใช้น้ำ</span>
            </vs-radio>
            <vs-input v-model="memberName" @keydown="onKeydown($event)" style="width: 170px; display: inline-block; margin-left: 10px" :disabled="disable03"/>
          </div>
        </div>
      </vx-card>
    </div>
    <div class="vx-col w-full mb-base">
      <vs-tabs alignment="fixed" v-model="tabActive">
        <vs-tab v-for="item in tabName" :key="item" :label="item" class="p-0">
          <div class="vx-row mt-5">
            <div class="vx-col md:w-2/3 w-full">
              <div id="data-list-list-view" class="data-list-container">
                <vs-table ref="table" v-model="selected" @selected="handleSelected" noDataText="ไม่พบข้อมูล" pagination :max-items="itemsPerPage" :data="datas">
                  <template slot="thead">
                    <vs-th>ลำดับที่</vs-th>
                    <vs-th>รหัสสมาชิก</vs-th>
                    <vs-th>ชื่อสกุล</vs-th>
                    <vs-th>พื้นที่/กลุ่ม</vs-th>
                    <vs-th>วันที่ส่ง</vs-th>
                  </template>
                  <template slot-scope="{data}">
                    <tbody>
                      <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                        <vs-td>
                          <p class="product-name font-medium">{{ indextr + 1 }}</p>
                        </vs-td>
                        <vs-td>
                          <p class="product-name font-medium">{{ tr.member_id }}</p>
                        </vs-td>
                        <vs-td>
                          <p class="product-name font-medium">{{ tr.member_name }}</p>
                        </vs-td>
                        <vs-td>
                          <p class="product-name font-medium">{{ tr.area }}</p>
                        </vs-td>
                        <vs-td>
                          <p class="product-name font-medium">{{ tr.sendDate | dateFormat }}</p>
                        </vs-td>
                      </vs-tr>
                    </tbody>
                  </template>
                </vs-table>
              </div>
            </div>
            <div class="vx-col md:w-1/3 w-full">
            <div class="mt-5 mb-5">
              <b>รายละเอียด</b>
            </div>
              <vx-card class="detail">
                <div class="vx-row">
                  <div class="vx-col w-full text-center">
                    <vs-icon v-if="pathImg === ''" icon="broken_image" style="font-size: 250px"></vs-icon>
                    <img v-else :src="pathImg" class="rounded w-3/4 mt-5 mb-5" style="display: inline-block"/>
                  </div>
                </div>
                <div class="vx-row">
                  <div class="vx-col w-full">
                    <p>เลขทะเบียนผู้ใช้น้ำ: {{ selected.member_id }}</p>
                    <p>ชื่อสกุล: {{ selected.member_name }}</p>
                    <p>พื้นที่/กลุ่ม: {{ selected.area }}</p>
                    <p>ที่อยู่: {{ selected.member_address }} {{ selected.member_soi }} {{ selected.member_road }} {{ selected.member_address_detail }}</p>
                    <p>รหัสรอบบิล: {{ selected.B01 }}</p>
                    <p>ชื่อรอบบิล: {{ selected.CB04 }}</p>
                    <p>วันที่จด: {{ selected.B19 }}</p>
                    <p>ค่าน้ำรวม: {{ selected.sum }}</p>
                    <p>วันที่ส่ง: {{ selected.sendDate | dateFormat }}</p>
                  </div>
                </div>
                <div class="vx-row" v-if="tabActive === 0">
                  <div class="vx-col md:w-1/2 text-right w-full mt-3">
                    <vs-button color="danger" :disabled="disableBtn" @click="rejected()">ปฏิเสธ</vs-button>
                  </div>
                  <div class="vx-col md:w-1/2 w-full mt-3">
                    <vs-button color="primary" :disabled="disableBtn" @click="approved()">ยอมรับ</vs-button>
                  </div>
                </div>
              </vx-card>
            </div>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </div>
</template>
<script>
  import moduleSlip from "@/store/slip/moduleSlip.js";
  import moduleArea from "@/store/area/moduleArea.js";
  import vSelect from 'vue-select';
  import {formatNumber2Digit, formatNumber} from "./../../assets/utils";

  export default {
    components: {
      vSelect
    },
    data() {
      return {
        radioBtn: '01',
        disable01: false,
        disable02: true,
        disable03: true,
        inputNumber: '',
        memberName: '',
        selectedArea: { label: 'ทั้งหมด', value: 'all' },
        selected: [],
        datas: [],
        itemsPerPage: 20,
        baseUrl: 'http://188.166.177.25/',
        pathImg: '',
        disableBtn: true,
        tabActive: 0,
        payloadFilter: {},
        tabName: ['รอตรวจสอบ', 'ชำระเงินแล้ว', 'รอส่งหลักฐานการชำเงินใหม่']
      }
    },
    computed: {
      areas() {
        let options = [];
        this.$store.state.area.items.forEach(function (item) {
          options.push({
            label: item.A02,
            value: item.A01
          });
        });
        options.unshift({
          label: 'ทั้งหมด',
          value: 'all'
        });
        return options;
      },
    },
    watch: {
      tabActive(val) {
        let payload = this.payloadFilter
        if (val === 0) {
          payload.status = 'waiting';
        } else if (val === 1) {
          payload.status = 'approved';
        } else {
          payload.status = 'rejected';
        }
        this.pathImg = ''
        this.selected = []
        this.disableBtn = true
        this.getSlips(payload)
      },
      radioBtn: {
        handler(val) {
          this.disable01 = true;
          this.disable02 = true;
          this.disable03 = true;
          if (val === '01') {
            this.disable01 = false;
          } else if (val === '02') {
            this.disable02 = false;
          } else if (val === '03') {
            this.disable03 = false;
          }
          this.inputNumber = '';
          this.memberName = '';
        },
        deep: true
      },
    },
    methods: {
      formatWater(BA11, BA12, BA13){
        const sumBA11 = parseFloat(BA11)
        const sumBA12 = parseFloat(BA12)
        const sumBA13 = parseFloat(BA13)
        const sum = sumBA11 + sumBA12 + sumBA13
        return this.formatNumber(sum)
      },
     async init() {
        const payload = {
          status: 'waiting'
        }
        this.getSlips(payload)
      },
      onKeydown(e) {
        if (e.keyCode == 13 || e.which == 13) {
          let payload = this.payloadFilter
          if (this.radioBtn === '02') {
            if(this.inputNumber === '' || this.inputNumber === null) {
              this.dialog("warning", "คำเตือน", "กรุณากรอกเลขทะเบียนผู้ใช้น้ำ");
              return
            }
            payload.member_id = this.inputNumber
          } else if (this.radioBtn === '03') {
            if(this.inputNumber === '' || this.inputNumber === null) {
              this.dialog("warning", "คำเตือน", "กรุณากรอกชื่อผู้ใช้น้ำ");
              return
            }
            payload.member_name = this.memberName
          }
          this.pathImg = ''
          this.selected = []
          this.disableBtn = true
          this.getSlips(payload)
        }
      },
      setArea() {
        let payload = this.payloadFilter
        payload.area = this.selectedArea.value
        this.getSlips(payload)
      },
      handleSelected(tr) {
        if(tr.image_url !== '' && tr.image_url !== null && tr.image_url !== undefined) {
          this.pathImg = this.baseUrl + tr.image_url
        } else {
          this.pathImg = ''
        }
        this.disableBtn = false
      },
      async getSlips(payload) {
        this.payloadFilter = payload
        this.$vs.loading();
        await this.$store.dispatch("slip/fetchDataListItemsByFilter", payload).then((res) => {
          if (res.status === 200) {
            const users = res.data.map((element, index) => {
              var obj = Object.assign({}, element);
              obj.index = index;
              return obj;
            })
            this.datas = users
          } else {
            this.datas = []
          }
          this.$vs.loading.close()
        }).catch((error) => {
          this.datas = []
          this.$vs.loading.close()
        });
      },
      async approved() {
        const user = JSON.parse(sessionStorage.getItem('vuex'));
        const staffId = user.owner.user.user._id;
        const payload = {
          id: this.selected._id,
          status: 'approved',
          staff_id: staffId,
        }
        await this.$store.dispatch("slip/updateItem", payload).then((res) => {
          if(res.status === 200) {
            this.datas.splice(this.selected.index, 1);
            this.pathImg = ''
            this.selected = []
            this.disableBtn = true
          } else {
            this.dialog("danger", "ผิดพลาด", "ยอมรับไม่สำเร็จ กรุณาลองใหม่");
          }
        }).catch((error) => {
          this.dialog("danger", "ผิดพลาด", "ยอมรับไม่สำเร็จ กรุณาลองใหม่");
        })
      },
      async rejected() {
        const payload = {
          id: this.selected._id,
          status: 'rejected'
        }
        await this.$store.dispatch("slip/updateItem", payload).then((res) => {
          if(res.status === 200) {
            this.datas.splice(this.selected.index, 1);
            this.pathImg = ''
            this.selected = []
            this.disableBtn = true
          } else {
            this.dialog("danger", "ผิดพลาด", "ปฏิเสธไม่สำเร็จ กรุณาลองใหม่");
          }
        }).catch((error) => {
          this.dialog("danger", "ผิดพลาด", "ปฏิเสธไม่สำเร็จ กรุณาลองใหม่");
        })
      },
      dialog(type, title, message) {
      this.$vs.dialog({
        type: "alert",
        color: type,
        title: title,
        text: message,
        acceptText: "ปิด",
      });
    },
    },
    async created() {
      if (!moduleSlip.isRegistered) {
        this.$store.registerModule('slip', moduleSlip);
        moduleSlip.isRegistered = true;
      }
      if (!moduleArea.isRegistered) {
        this.$store.registerModule('area', moduleArea);
        moduleArea.isRegistered = true
      }
      await this.$store.dispatch("area/fetchDataListItems");
      this.init();
    }
  }
</script>
<style lang="scss">
  .input-bill {
    width: 260px;
  }
    #data-list-list-view {
    .vs-con-table {

      @media (max-width: 689px) {
        .vs-table--search {
          margin-left: 0;
          max-width: unset;
          width: 100%;

          .vs-table--search-input {
            width: 100%;
          }
        }
      }

      @media (max-width: 461px) {
        .items-per-page-handler {
          display: none;
        }
      }

      @media (max-width: 341px) {
        .data-list-btn-container {
          width: 100%;

          .dd-actions,
          .btn-add-new {
            width: 100%;
            margin-right: 0 !important;
          }
        }
      }

      .product-name {
        max-width: 23rem;
      }

      .vs-table--header {
        display: flex;
        flex-wrap: wrap;
        margin-left: 1.5rem;
        margin-right: 1.5rem;
        > span {
          display: flex;
          flex-grow: 1;
        }

        .vs-table--search{
          padding-top: 0;

          .vs-table--search-input {
            padding: 0.9rem 2.5rem;
            font-size: 1rem;

            &+i {
              left: 1rem;
            }

            &:focus+i {
              left: 1rem;
            }
          }
        }
      }

      .vs-table {
        border-collapse: separate;
        border-spacing: 0 1.3rem;
        padding: 0 1rem;

        tr{
          box-shadow: 0 4px 20px 0 rgba(0,0,0,.05);
          td{
            padding: 20px;
            &:first-child{
              border-top-left-radius: .5rem;
              border-bottom-left-radius: .5rem;
            }
            &:last-child{
              border-top-right-radius: .5rem;
              border-bottom-right-radius: .5rem;
            }
          }
          td.td-check{
            padding: 20px !important;
          }
        }
      }

      .vs-table--thead{
        th {
          padding-top: 0;
          padding-bottom: 0;

          .vs-table-text{
            text-transform: uppercase;
            font-weight: 600;
          }
        }
        th.td-check{
          padding: 0 15px !important;
        }
        tr{
          background: none;
          box-shadow: none;
        }
      }

      .vs-table--pagination {
        justify-content: center;
      }
    }
  }
  .vx-card.detail .vx-card__collapsible-content .vx-card__body {
    padding-top: 0;
  }
</style>
