import state from './moduleSlipState.js'
import mutations from './moduleSlipMutations.js'
import actions from './moduleSlipActions.js'
import getters from './moduleSlipGetters.js'

export default {
  isRegistered: false,
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions,
  getters: getters
}